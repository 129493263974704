<template>
  <div style="height: 100%;">
    <v-container class="container__assortment-step pt-0">
      <template v-if="$IsDebugMode()">
        <v-row><v-col>null_category_selected:{{ stepperData.null_category_selected }}</v-col></v-row>
        <v-row><v-col>nomenclature_category_uuids:{{ stepperData.nomenclature_category_uuids }}</v-col></v-row>
        <v-row><v-col>nomenclature_uuids:{{ stepperData.nomenclature_uuids }}</v-col></v-row>
      </template>
      <v-form
        ref="form"
      >
        <BaseMasterFieldBlock
          title="Торговые точки"
          description="Выберите на каких торговых точках будет действовать данная акция."
        >
          <template v-slot:input>
            <v-row>
              <v-col>
                <base-ext-switch
                  v-model="stepperData.all_shops"
                  class="switch__all-product"
                  label="Действует на всех торговых точках"
                />
              </v-col>
            </v-row>
            <v-row v-if="!stepperData.all_shops">
              <v-col>
                <shop-select
                  v-model="stepperData.program_shop_ids"
                  multiple
                  :rules="shopSelectRules"
                />
              </v-col>
            </v-row>
          </template>
        </BaseMasterFieldBlock>
        <BaseMasterFieldBlock
          title="Ассортимент"
          description="Выберите товары, категории или сегменты товаров, на которые будет действовать акция."
        >
          <template v-slot:input>
            <v-row>
              <v-col>
                <category-and-table
                  :stepper-data="stepperData"
                  @update:stepperData="$emit('update:stepperData', $event)"
                  @continue="isEdit ? $emit('save') : $emit('continue')"
                />
              </v-col>
            </v-row>
          </template>
        </BaseMasterFieldBlock>
      </v-form>
    </v-container>
  </div>
</template>

<script>
  // import { deepClone } from '@/utils'

  export default {
    components: {
      CategoryAndTable: () => import('../stepComponents/CategoryAndTable.vue'),
      ShopSelect: () => import('@/components/ModelSelect/ShopSelect'),
    },
    computed: {
      shopSelectRules () {
        if (!this.stepperData.all_shops) {
          return  [v => v.length > 0 || 'Обязательно для заполнения',]
        }
        return []
      }
    },
    props: {
      stepperData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    inject: ['isEdit'],
    data () {
      return {
      }
    },
    watch: {
      'stepperData.all_nomenclature' (v) {
        // if (v) {
        //   this.stepperData.nomenclature_uuids = []
        //   this.stepperData.nomenclature_category_uuids = []
        // }
      },
    },
    mounted () {

    },
    methods: {
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/_typography";
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";

.container__assortment-step {
  .btn__next {
    margin-top: 10px;
  }
  .btn__add-integration {
    border-radius: 10px;
    .icon {
      margin-right: 8px;
    }
  }
  .switch__all-product {
    margin-top: -10px;
  }
  .master-block__assortments {
    margin-top: 9px;
  }
}
</style>
